/* ConfirmationModal.css */

/* Estilo geral do modal */
.confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Fundo escuro com opacidade */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Garante que o modal fica acima de outros elementos */
    color: white;
  }
  
  /* Conteúdo central do modal */
  .confirmation-modal > div {
    background: #3b302a;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  /* Título do modal */
  .confirmation-modal h3 {
    margin-top: 0;
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 20px;
    color: white;
  }
  
  /* Texto de descrição */
  .confirmation-modal .desc p {
    font-size: 1rem;
    color: white;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  /* Botões */
  .confirmation-modal button {
    background: white;
    color: #3b302a;
    border: none;
    padding: 10px 20px;
    margin: 0 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.3s ease;
  }
  
  .confirmation-modal button:hover {
    background: #0056b3;
  }
  
  .confirmation-modal button:last-child {
    background: #6c757d; /* Cinza para o botão de Cancelar */
    color: white;
  }
  
  .confirmation-modal button:last-child:hover {
    background: #5a6268;
  }
  